import Swiper, { Navigation, Pagination, Autoplay, EffectFade, EffectCube } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, EffectFade, EffectCube]);

const init = () => {


    document.querySelectorAll('.swiper').forEach(function (el, key, parent){
        const NEXT = '.swiper-button-next';
        const PREV = '.swiper-button-prev';



        // config presets
        let config = {
            "default": {
                speed: 1000,
                slidesPerView: 1,
                spaceBetween: 30,
                navigation: parseNavigationOptions(el, {
                    navigation: true
                }),
                // using "ratio" endpoints
                breakpoints: {
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }
            },
            "imageSlider": {

            },
            "anchorNavigation": {
                slidesPerView: "auto",
                setWrapperSize: true,
                freeMode: true,
                freeModeSticky: true,
                watchOverflow: true,
            }
        }

        let data = null;    // json from data-swiper attribute
        let configurationPreset = {};
        let useConfiguration = false;
        let options = {};

        try {
            data = JSON.parse(el.getAttribute('data-swiper'));
        } catch (e) {
            // throw e;
            let configurationKey = el.getAttribute('data-swiper');

            if(config[configurationKey] !== undefined){
                configurationPreset = config[configurationKey];
                useConfiguration = true;
            }
        }


        if(useConfiguration === false){

            let pagination = {
                el: '.swiper-pagination',
                dynamicBullets: true,
            };
            pagination = false;

            options = {
                autoplay: parseBoolean(data.autoplay, false),
                speed: parseIntNotEmpty(data.speed, config.default.speed),
                freeMode: parseBoolean(data.freeMode, false),
                slidesPerView: parseSlidesPerView(data.slidesPerView, 1),
                //spaceBetween: parseIntNotEmpty(data.spaceBetween, 0),
                effect: data.effect ?? null,
                loop:  parseBoolean(data.loop, true),
                pagination: pagination,
                navigation: {
                    nextEl: NEXT,
                    prevEl: PREV,
                },
                spaceBetween: 30,
                //navigation: parseNavigationOptions(el, data),
                // using "ratio" endpoints
                // breakpoints: {
                //     '@0.75': {
                //         slidesPerView: 2,
                //         spaceBetween: 20,
                //     },
                //     '@1.00': {
                //         slidesPerView: 3,
                //         spaceBetween: 40,
                //     },
                //     '@1.50': {
                //         slidesPerView: 4,
                //         spaceBetween: 50,
                //     },
                // }

            };

        } else{
            options = configurationPreset;
        }

        // console.log('Options: ', options)
        const swiper = new Swiper(el, options);

    })

}

function parseNavigationOptions(element,data){
    const NEXT = '.swiper-button-next';
    const PREV = '.swiper-button-prev';

    let isNavEnabled = parseBoolean(data.navigation, false)
    if(isNavEnabled === true){
        removeClass(element, NEXT, 'd-none');
        removeClass(element, PREV, 'd-none');

        return {
            nextEl: NEXT,
            prevEl: PREV,
        };
    }

    return false;
}


function parsePaginationOptions(element,data){
    const NEXT = '.swiper-button-next';
    const PREV = '.swiper-button-prev';

    let isNavEnabled = parseBoolean(data.navigation, false)
    if(isNavEnabled === true){
        removeClass(element, NEXT, 'd-none');
        removeClass(element, PREV, 'd-none');

        return {
            nextEl: NEXT,
            prevEl: PREV,
        };
    }

    return false;
}

function removeClass(parent, selector, className) {
    parent.querySelectorAll(selector).forEach(function (a) { a.classList.remove(className); });
}

function parseIntNotEmpty(value, def){
    let v = parseInt(value);
    return (v > 0) ? v: def;
}

function parseSlidesPerView(value, def){
    if(value == 'auto'){
        return value;
    }
    return parseIntNotEmpty(value,def);
}

function parseBoolean(value, def){
    return (value == 'true' || value === true) ? true: def;
}

export default init

