import '@grubersjoe/slide-menu'

const init = () => {

    const slideMenu = document.getElementById('slideMenu');
    const slideMenuButton = document.getElementById('slideMenuButton');
    const body = document.getElementsByTagName('body').item(0);
    const current = document.querySelectorAll('.slide-menu .menu-item-active > .with-childs');
    const overlay = document.querySelectorAll('.slide-menu-overlay').item(0) ?? null;


    if (slideMenu && body) {
        const menu = new SlideMenu(slideMenu, {
            // submenuLinkAfter: ' <strong>⇒</strong>',
            showBackLink: true
            // submenuLinkBefore: "12"
        });



        const slideMenuClose = document.getElementById('slideMenuClose');
        // const slideMenuBack = document.getElementById('slideMenuBack');

        slideMenuClose.addEventListener('click', function (e){
            menu.close();
        });

        // slideMenuBack.addEventListener('click', function (e){
        //     menu.back();
        // });

        slideMenuButton.addEventListener('click', function (e){
           menu.toggle();
        });

        slideMenu.addEventListener('sm.open', function () {

            body.classList.add('overflow-hidden');
            overlay.classList.add('show');
            overlay.classList.add('animate__fadeIn');

            // console.log(body)
            // console.log(overlay)
            console.log('The menu has opened', menu);
        });

        slideMenu.addEventListener('sm.close-after', function () {
            body.classList.remove('overflow-hidden')
            overlay.classList.remove('show');
            overlay.classList.remove('animate__fadeIn');

            // console.log('The menu has opened', menu);
        });


        slideMenu.addEventListener('sm.navigate-after', function () {

            console.log('The menu has opened', menu);
        });

        slideMenu.addEventListener('sm.back-after', function () {

            console.log('The menu has bak', menu);
        });


        slideMenu.addEventListener('sm.forward-after', function () {

            console.log('The menu has forwrd', menu);
        });


    }

    if(current && current.length > 0) {
        console.log(current)
        current.item(current.length - 1).click();

    }

}


export default init
