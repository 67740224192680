
const init = () => {

    let counterItems = document.querySelectorAll('.counter-item .counter-item-count');
    counterItems.forEach( counter => {

        let counterValue = 0;

        const animate = () => {
            let value = +counter.getAttribute('data-to');
            const data = +counterValue;
            const speed = counter.getAttribute('data-speed') ?? 200;
            const time = value / speed;
            if(data < value) {
                counterValue = Math.ceil(data + time);
                setValue(counterValue);
                setTimeout(animate, 1);
            }else{
                setValue(counterValue)
            }
        }

        const setValue = (value) => {
            let a = new Number(value);
            counter.innerText =  a.toLocaleString();
        }

        animate();
    });
}

export default init;
