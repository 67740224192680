// import Swiper JS
import runSwiper from './modules/swiper';
import runCounter from './modules/counter';
import initNewsticker from './modules/newsticker';
import initCountdown from './modules/countdown'
import {initLightbox} from './modules/glightbox';
import slideMenu from './modules/slide-menu';



const bootstrap = require('bootstrap');


document.addEventListener("DOMContentLoaded", function () {

  runSwiper();
  runCounter();
  initNewsticker();
  initCountdown();
  initLightbox();
  slideMenu();

  // sticky top
  const navbarMain = document.getElementById('navbarMain');
  if(navbarMain) {
    window.addEventListener('scroll', function() {

      if(navbarMain && navbarMain.classList.contains('js-sticky')){
        if (window.scrollY > 100) {
          navbarMain.classList.add('bg-light');
          // add padding top to show content behind navbar
          let navbar_height = document.getElementById('navbarMain').offsetHeight;
          // document.body.style.paddingTop = navbar_height + 'px';
        } else {
          // document.getElementById('navbarMain').classList.add('fixed-top');
          document.getElementById('navbarMain').classList.remove('bg-light');
          // remove padding top from body
          // document.body.style.paddingTop = '0';
        }
      }
    });
  }


  const scrollNav = document.body.querySelector('#scrollNav');
  if (scrollNav) {
    // console.log(bootstrap)
    let a = new bootstrap.ScrollSpy(document.body, {
      target: '#scrollNav',
      offset: 74,
    });
  };

  document.querySelectorAll('.anchor-navigation').forEach(function (el, key, parent) {
    let a = new bootstrap.ScrollSpy(document.body, {
      target: el,
      offset: 74,
    });
  });

  const body = document.querySelector('body');

  window.addEventListener('scroll', function() {
    let scroll = Math.round(window.scrollY);
    body.style.setProperty('--scroll',scroll);
  });


  let navbarMainWithOverlay = document.getElementById('navbarMainWithOverlay');

  if(navbarMainWithOverlay) {

  }


});
