const GLightbox = require('glightbox')

const newInstance = () => {
    // your method logic
}

const init = () => {
//
//
//     const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
//     <div class="gloader visible"></div>
//     <div class="goverlay"></div>
//     <div class="gcontainer">
//     <div id="glightbox-slider" class="gslider"></div>
//     <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>
//     <button class="gprev gbtn" tabindex="1" aria-label="Previous">{prevSVG}xxx</button>
//     <button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}xxx</button>
// </div>
// </div>`;
//
    let customSlideHTML = `<div class="gslide">
    <div class="container-lg">
        <div class="ginner-container">
        
            <div class="gslide-media">
            </div>
            <div class="gslide-description">
                <div class="gdesc-inner">
                    <h4 class="gslide-title"></h4>
                    <div class="gslide-desc"></div>
                </div>
            </div>
        </div>
    </div>
</div>`;
//
//

    const options = {
        selector: '.glightbox-gallery',
        // width: "90vw",
        height: "90vh",
        // moreLength: 0,
        zoomable: false,
        touchNavigation: false,
        loop: true,
        autoplayVideos: false,
        // lightboxHTML: customLightboxHTML,
        slideHTML: customSlideHTML,
        skin: 'base'
    };

    const lightbox = GLightbox(options);

}

module.exports = {
    initLightbox: init,
    newInstance: newInstance,
};
