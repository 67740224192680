import maqruee from 'vanilla-marquee';
import * as _ from 'lodash';

const init = () => {

  let newstickers = document.querySelectorAll('.news-ticker');

  for (let newsticker of newstickers) {

    let data = newsticker.getAttribute('data-options');
    let options = {};
    if(data){
      options = JSON.parse(data);
    }

    let defaultOptions = {
      speed: 100,
      pauseOnHover: true,
      direction: 'left',
      duration: 5000,
      duplicated: true,
      gap: 50,
      startVisible: true,
      delayBeforeStart: 3000,
      recalcResize: true
    }

    let opts = _.merge(defaultOptions, options);
    let list = newsticker.getElementsByClassName('news-content');
    for (let item of list) {

      let maq = new maqruee(item, opts);
    }
  }
}



export default init

