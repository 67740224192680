// https://github.com/albert-gonzalez/easytimer.js

import Timer from 'easytimer.js'

const init = () => {


    let countdowns = document.querySelectorAll('.countdown');
    countdowns.forEach( countdown => {

        let elSeconds = countdown.querySelectorAll('.coundown-item-seconds .countdown-item-count')
        let elDays = countdown.querySelectorAll('.coundown-item-days .countdown-item-count')
        let elMinutes = countdown.querySelectorAll('.coundown-item-minutes .countdown-item-count')
        let elHour = countdown.querySelectorAll('.coundown-item-hours .countdown-item-count')
        const timer = new Timer();

        const setToNodeList = (list, value) => {
            if(list && list.length > 0){
                list.forEach(el => {

                    el.innerHTML = value;
                })
            }
        }

        timer.addEventListener("secondsUpdated", function (e) {
            let values = timer.getTimeValues();
            setToNodeList(elSeconds, values.seconds)
            setToNodeList(elDays, values.days)
            setToNodeList(elMinutes, values.minutes)
            setToNodeList(elHour, values.hours)
        });

        let date = new Date('December 9, 2022 05:24:00');
        let now = new Date();
        let start = parseInt((date.getTime() - now.getTime()) /1000);
        if(date > now){
            timer.start({
                countdown: true,
                startValues: {
                    seconds: start
                },
                target: {
                    seconds: 0
                }
            })
        }
    });
}



//
//
// var timer = new Timer();
// timer.start();
// timer.addEventListener('secondsUpdated', function (e) {
//     $('#basic').html(timer.getTimeValues().toString());
// });
//
// var timer2 = new Timer();
// timer2.start({precision: 'seconds'});
// timer2.addEventListener('secondsUpdated', function (e) {
//     $('#gettingValuesExample .days').html(timer2.getTimeValues().days);
//     $('#gettingValuesExample .hours').html(timer2.getTimeValues().hours);
//     $('#gettingValuesExample .minutes').html(timer2.getTimeValues().minutes);
//     $('#gettingValuesExample .seconds').html(timer2.getTimeValues().seconds);
//     $('#gettingValuesExample .secondTenths').html(timer2.getTimeValues().secondTenths);
//
//     $('#gettingTotalValuesExample .days').html(timer2.getTotalTimeValues().days);
//     $('#gettingTotalValuesExample .hours').html(timer2.getTotalTimeValues().hours);
//     $('#gettingTotalValuesExample .minutes').html(timer2.getTotalTimeValues().minutes);
//     $('#gettingTotalValuesExample .seconds').html(timer2.getTotalTimeValues().seconds);
//     $('#gettingTotalValuesExample .secondTenths').html(timer2.getTotalTimeValues().secondTenths);
// });
// var timer3 = new Timer();
// $('#chronoExample .startButton').click(function () {
//     timer3.start();
// });
// $('#chronoExample .pauseButton').click(function () {
//     timer3.pause();
// });
// $('#chronoExample .stopButton').click(function () {
//     timer3.stop();
// });
// timer3.addEventListener('secondsUpdated', function (e) {
//     $('#chronoExample .values').html(timer3.getTimeValues().toString());
// });
// timer3.addEventListener('started', function (e) {
//     $('#chronoExample .values').html(timer3.getTimeValues().toString());
// });
// var timer4 = new Timer();
// timer4.start({countdown: true, startValues: {seconds: 30}});
// $('#countdownExample .values').html(timer4.getTimeValues().toString());
// timer4.addEventListener('secondsUpdated', function (e) {
//     $('#countdownExample .values').html(timer4.getTimeValues().toString());
// });
// timer4.addEventListener('targetAchieved', function (e) {
//     $('#countdownExample .values').html('KABOOM!!');
// });
//

export default init

